import permissions from '@/helpers/permissions'

export enum AdminRoutes {
  index = 'admin',
  dashboard = 'admin.dashboard',
  profile = 'admin.profile',
  fundingRequests = 'admin.regular.funding-requests',
  fundingRequestsIndex = 'admin.regular.funding-requests.index',
  fundingRequestsShow = 'admin.regular.funding-requests.show',
  fundingRequestsEdit = 'admin.regular.funding-requests.edit',

  fundingRequestsShowTabOffers = 'admin.regular.funding-requests.tabs.offers',
  fundingRequestsShowTabDocuments = 'admin.regular.funding-requests.tabs.documents',
  fundingRequestsShowTabVerifications = 'admin.regular.funding-requests.tabs.verifications',
  fundingRequestsShowTabAssignedUsers = 'admin.regular.funding-requests.tabs.assigned-users',
  fundingRequestsShowTabActivityLog = 'admin.regular.funding-requests.tabs.activity-log',
  fundingRequestsShowTabAssignedFinanciers = 'admin.regular.funding-requests.tabs.assigned-financiers',
  fundingRequestsShowTabDebts = 'admin.regular.funding-requests.tabs.debts',
  fundingRequestsShowTabFinances = 'admin.regular.funding-requests.tabs.finances',
  fundingRequestsShowTabDsti = 'admin.regular.funding-requests.tabs.dsti',

  selectedOffersShowTabOffers = 'admin.regular.selected-offers.tabs.offers',
  selectedOffersShowTabDocuments = 'admin.regular.selected-offers.tabs.documents',
  selectedOffersShowTabVerifications = 'admin.regular.selected-offers.tabs.verifications',
  selectedOffersShowTabActivityLog = 'admin.regular.selected-offers.tabs.activity-log',

  selectedBusinessOffersShowTabOffers = 'admin.business.selected-offers.tabs.offers',
  selectedBusinessOffersShowTabDocuments = 'admin.business.selected-offers.tabs.documents',
  selectedBusinessOffersShowTabVerifications = 'admin.business.selected-offers.tabs.verifications',
  selectedBusinessOffersShowTabActivityLog = 'admin.business.selected-offers.tabs.activity-log',

  declinedFundingRequests = 'admin.regular.declined-funding-requests',
  declinedFundingRequestsIndex = 'admin.regular.declined-funding-requests.index',

  archivedFundingRequests = 'admin.regular.archived-funding-requests',
  archivedFundingRequestsIndex = 'admin.regular.archived-funding-requests.index',

  offers = 'admin.regular.offers',
  offersIndex = 'admin.regular.offers.index',
  offersShow = 'admin.regular.offers.show',
  offersEdit = 'admin.regular.offers.edit',

  selectedOffers = 'admin.regular.selected-offers',
  selectedOffersIndex = 'admin.regular.selected-offers.index',
  selectedOffersShow = 'admin.regular.selected-offers.show',

  businessFundingRequests = 'admin.business.funding-requests',
  businessFundingRequestsIndex = 'admin.business.funding-requests.index',
  businessFundingRequestsShow = 'admin.business.funding-requests.show',
  businessFundingRequestsEdit = 'admin.business.funding-requests.edit',

  businessFundingRequestsShowTabOffers = 'admin.business.funding-requests.tabs.offers',
  businessFundingRequestsShowTabDocuments = 'admin.business.funding-requests.tabs.documents',
  businessFundingRequestsShowTabVerifications = 'admin.business.funding-requests.tabs.verifications',
  businessFundingRequestsShowTabAssignedUsers = 'admin.business.funding-requests.tabs.assigned-users',
  businessFundingRequestsShowTabActivityLog = 'admin.business.funding-requests.tabs.activity-log',
  businessFundingRequestsShowTabAssignedFinanciers = 'admin.business.funding-requests.tabs.assigned-financiers',

  declinedBusinessFundingRequests = 'admin.business.declined-funding-requests',
  declinedBusinessFundingRequestsIndex = 'admin.business.declined-funding-requests.index',

  businessOffers = 'admin.business.offers',
  businessOffersIndex = 'admin.business.offers.index',
  businessOffersShow = 'admin.business.offers.show',
  businessOffersEdit = 'admin.business.offers.edit',

  selectedBusinessOffers = 'admin.business.selected-business-offers',
  selectedBusinessOffersIndex = 'admin.business.selected-business-offers.index',
  selectedBusinessOffersShow = 'admin.business.selected-business-offers.show',

  customers = 'admin.customers',
  customersIndex = 'admin.customers.index',
  customersCreate = 'admin.customers.create',
  customersEdit = 'admin.customers.edit',
  customersEditBasic = 'admin.customers.edit.basic',
  customersEditPermissions = 'admin.customers.edit.permissions',

  employees = 'admin.employees',
  employeesIndex = 'admin.employees.index',
  employeesCreate = 'admin.employees.create',
  employeesEdit = 'admin.employees.edit',
  employeesEditBasic = 'admin.employees.edit.basic',
  employeesEditPermissions = 'admin.employees.edit.permissions',
  employeesEditFinanciers = 'admin.employees.edit.financiers',

  colleagues = 'admin.colleagues',
  colleaguesIndex = 'admin.colleagues.index',
  colleaguesShow = 'admin.colleagues.show',

  settings = 'admin.settings',

  settingsFinanciers = 'admin.settings.financiers',
  settingsFinanciersIndex = 'admin.settings.financiers.index',
  settingsFinanciersCreate = 'admin.settings.financiers.create',
  settingsFinanciersEdit = 'admin.settings.financiers.edit',

  settingsAdditionalDocuments = 'admin.settings.additionalDocuments',
  settingsAdditionalDocumentsIndex = 'admin.settings.additionalDocuments.index',
  settingsAdditionalDocumentsCreate = 'admin.settings.additionalDocuments.create',
  settingsAdditionalDocumentsEdit = 'admin.settings.additionalDocuments.edit',

  settingsUserData = 'admin.settings.user-data',
  settingsNotifications = 'admin.settings.notifications',
  settingsRoles = 'admin.settings.roles',
  settingsRolesIndex = 'admin.settings.roles.index',
  settingsRolesCreate = 'admin.settings.roles.create',
  settingsRolesEdit = 'admin.settings.roles.edit',
  settingsRecommendations = 'admin.settings.recommendations',

  activityLog = 'admin.activity-log',
  activityLogIndex = 'admin.activity-log.index',

  login = 'admin.login',
  remindPassword = 'admin.remind-password',
  resetPassword = 'admin.reset-password',
}

const routesMeta: any = {
  // Dashboard
  [AdminRoutes.dashboard]: {
    permission: permissions.dashboard.list,
    shouldLoggedIn: true,
    redirect: AdminRoutes.fundingRequests,
  },

  // Offers page
  [AdminRoutes.fundingRequests]: {
    permission: permissions.fundingRequests.list,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  // Funding requests page
  [AdminRoutes.fundingRequestsIndex]: {
    permission: permissions.fundingRequests.list,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  // Funding requests page
  [AdminRoutes.fundingRequestsShow]: {
    permission: permissions.fundingRequests.show,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
    dontScroll: true,
  },

  [AdminRoutes.fundingRequestsEdit]: {
    permission: permissions.fundingRequests.constrainedUpdate,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
    dontScroll: true,
  },

  // Offers page
  [AdminRoutes.offers]: {
    permission: permissions.fundingRequests.hasOffers,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  // Offers List page
  [AdminRoutes.offersIndex]: {
    permission: permissions.offers.list,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  // Offers Show page
  [AdminRoutes.offersShow]: {
    permission: permissions.offers.show,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  // Offers Edit page
  [AdminRoutes.offersEdit]: {
    permission: permissions.offers.edit,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  [AdminRoutes.selectedOffers]: {
    permission: permissions.fundingRequests.offerAccepted,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  [AdminRoutes.selectedOffersIndex]: {
    permission: permissions.fundingRequests.offerAccepted,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  [AdminRoutes.selectedOffersShow]: {
    permission: permissions.fundingRequests.offerAccepted,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  // Offers page
  [AdminRoutes.businessFundingRequests]: {
    permission: permissions.businessFundingRequests.list,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  // Funding requests page
  [AdminRoutes.businessFundingRequestsIndex]: {
    permission: permissions.businessFundingRequests.list,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  // Funding requests page
  [AdminRoutes.businessFundingRequestsShow]: {
    permission: permissions.businessFundingRequests.show,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
    dontScroll: true,
  },

  [AdminRoutes.businessFundingRequestsEdit]: {
    permission: permissions.businessFundingRequests.constrainedUpdate,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
    dontScroll: true,
  },

  [AdminRoutes.businessOffers]: {
    permission: permissions.businessFundingRequests.list,
    shouldLoggedIn: true,
    redirect: AdminRoutes.businessFundingRequests,
  },

  // Offers List page
  [AdminRoutes.businessOffersIndex]: {
    permission: permissions.businessFundingRequests.list,
    shouldLoggedIn: true,
    redirect: AdminRoutes.businessFundingRequests,
  },

  // Offers Show page
  [AdminRoutes.businessOffersShow]: {
    permission: permissions.businessFundingRequests.show,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  // Offers Edit page
  [AdminRoutes.offersEdit]: {
    permission: permissions.offers.edit,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  [AdminRoutes.selectedOffers]: {
    permission: permissions.fundingRequests.offerAccepted,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  [AdminRoutes.selectedOffersIndex]: {
    permission: permissions.fundingRequests.offerAccepted,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  [AdminRoutes.selectedOffersShow]: {
    permission: permissions.fundingRequests.offerAccepted,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  // Offers page
  [AdminRoutes.businessFundingRequests]: {
    permission: permissions.businessFundingRequests.list,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  // Employees page
  [AdminRoutes.employees]: {
    permission: permissions.users.list,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  // Archived Fundings page
  [AdminRoutes.archivedFundingRequests]: {
    permission: permissions.archivedFundingRequests.list,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  // Employees create page
  [AdminRoutes.employeesCreate]: {
    permission: permissions.users.create,
    shouldLoggedIn: true,
    redirect: AdminRoutes.dashboard,
  },

  // Employees edit page
  [AdminRoutes.employeesEdit]: {
    initialRedirects: [
      AdminRoutes.employeesEditBasic,
      AdminRoutes.employeesEditPermissions,
      AdminRoutes.employeesEditFinanciers,
    ],
    shouldLoggedIn: true,
    mainRedirect: AdminRoutes.dashboard,
  },

  // Employees edit basic
  [AdminRoutes.employeesEditBasic]: {
    permission: permissions.users.edit,
    redirect: AdminRoutes.dashboard,
  },

  // Employees edit permissions
  [AdminRoutes.employeesEditPermissions]: {
    permission: permissions.users.edit,
    redirect: AdminRoutes.dashboard,
  },

  // Employees edit financiers
  [AdminRoutes.employeesEditFinanciers]: {
    permission: permissions.users.edit,
    redirect: AdminRoutes.dashboard,
  },

  // Settings page
  [AdminRoutes.settings]: {
    initialRedirects: [
      AdminRoutes.settingsFinanciersIndex,
      AdminRoutes.settingsAdditionalDocumentsIndex,
      AdminRoutes.settingsUserData,
      AdminRoutes.settingsNotifications,
    ],
    shouldLoggedIn: true,
    mainRedirect: AdminRoutes.dashboard,
  },

  // Settings Financiers
  [AdminRoutes.settingsFinanciersIndex]: {
    permission: permissions.financiers.list,
    redirect: AdminRoutes.dashboard,
  },
  [AdminRoutes.settingsFinanciersCreate]: {
    permission: permissions.financiers.create,
    redirect: AdminRoutes.dashboard,
  },
  [AdminRoutes.settingsFinanciersEdit]: {
    permission: permissions.financiers.edit,
    redirect: AdminRoutes.dashboard,
  },

  // Settings Additional Documents
  [AdminRoutes.settingsAdditionalDocumentsIndex]: {
    permission: permissions.additionalDocuments.list,
    redirect: AdminRoutes.dashboard,
  },
  [AdminRoutes.settingsAdditionalDocumentsCreate]: {
    permission: permissions.additionalDocuments.create,
    redirect: AdminRoutes.dashboard,
  },
  [AdminRoutes.settingsAdditionalDocumentsEdit]: {
    permission: permissions.additionalDocuments.edit,
    redirect: AdminRoutes.dashboard,
  },

  // Settings Funding request fields
  [AdminRoutes.settingsUserData]: {
    permission: permissions.fundingRequestFields.list,
    redirect: AdminRoutes.dashboard,
  },

  // Settings Notification templates
  [AdminRoutes.settingsNotifications]: {
    permission: permissions.notificationTemplates.list,
    redirect: AdminRoutes.dashboard,
  },

  // Settings Roles index
  [AdminRoutes.settingsRolesIndex]: {
    permission: permissions.roles.list,
    redirect: AdminRoutes.dashboard,
  },

  // Settings Roles create
  [AdminRoutes.settingsRolesCreate]: {
    permission: permissions.roles.create,
    redirect: AdminRoutes.dashboard,
  },

  // Settings Roles edit
  [AdminRoutes.settingsRolesEdit]: {
    permission: permissions.roles.edit,
    redirect: AdminRoutes.dashboard,
  },

  // Settings Recommendations
  [AdminRoutes.settingsRecommendations]: {
    permission: permissions.recommendations.list,
    redirect: AdminRoutes.dashboard,
  },
}

export default (name?: string): any => (name ? routesMeta[name] : routesMeta)
