import TextField from '@/components/Form/FieldTypes/TextField.vue'
import EmailField from '@/components/Form/FieldTypes/EmailField.vue'
import PasswordField from '@/components/Form/FieldTypes/PasswordField.vue'
import NumberField from '@/components/Form/FieldTypes/NumberField.vue'
import FileField from '@/components/Form/FieldTypes/FileField.vue'
import SelectField from '@/components/Form/FieldTypes/SelectField.vue'
import CheckboxField from '@/components/Form/FieldTypes/CheckboxField.vue'
import HtmlField from '@/components/Form/FieldTypes/HtmlField.vue'
import FileViewField from '@/components/Form/FieldTypes/FileViewField.vue'
import FilesViewField from '@/components/Form/FieldTypes/FilesViewField.vue'
import MultipleSelectField from '@/components/Form/FieldTypes/MultipleSelectField.vue'
import TextAreaField from '@/components/Form/FieldTypes/TextAreaField.vue'
import DatePickerField from '@/components/Form/FieldTypes/DatePickerField.vue'
import JSONField from '@/components/Form/FieldTypes/JSONField.vue'
import Toggle from '@/components/Form/FieldTypes/Toggle.vue'
import MessageField from '@/components/Form/FieldTypes/MessageField.vue'

export enum FieldTypes {
  text = 'text',
  email = 'email',
  password = 'password',
  number = 'number',
  file = 'file',
  select = 'select',
  multipleSelect = 'multiple-select',
  checkbox = 'checkbox',
  array = 'array',
  html = 'html',
  object = 'object',
  textArea = 'text-area',
  datePicker = 'date-picker',
  toggle = 'toggle',
  json = 'json',
  money = 'money',
  months = 'months',
  fileView = 'fileView',
  filesView = 'filesView',
  message = 'message',
}

export const fieldTypesMap: any = {
  [FieldTypes.text]: TextField,
  [FieldTypes.json]: JSONField,
  [FieldTypes.email]: EmailField,
  [FieldTypes.password]: PasswordField,
  [FieldTypes.number]: NumberField,
  [FieldTypes.file]: FileField,
  [FieldTypes.select]: SelectField,
  [FieldTypes.multipleSelect]: MultipleSelectField,
  [FieldTypes.checkbox]: CheckboxField,
  [FieldTypes.array]: () => import('@/components/Form/FieldTypes/ArrayField.vue'),
  [FieldTypes.html]: HtmlField,
  [FieldTypes.fileView]: FileViewField,
  [FieldTypes.filesView]: FilesViewField,
  [FieldTypes.object]: () => import('@/components/Form/FieldTypes/ObjectField.vue'),
  [FieldTypes.textArea]: TextAreaField,
  [FieldTypes.toggle]: Toggle,
  [FieldTypes.datePicker]: DatePickerField,
  [FieldTypes.message]: MessageField,
}
