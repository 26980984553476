








import { Component, Vue } from 'vue-property-decorator'
import Field, { FieldSizes } from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/Form/Form.vue'
import { Actions } from '@/store/global/actions'
import FinancierModel, {
  cipRiskGrade,
  declaredInLithuania,
  FinancierAttribute,
} from '@/modules/financier/financier.model'
import FormBase from '@/classes/Form/FormBase'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import SelectField from '@/classes/Form/Fields/Select/SelectField'
import { workExperienceValues } from '@/helpers/fundingRequest'
import {
  companyExistanceDuration,
  companyRevenueYearly,
  optionsToSelectItems,
} from '@/modules/step-form/business-field.model'
import http from '@/http'
import { AxiosError } from 'axios'
import { IDistricts, IFundingRequestType, IGuaranteesType } from '@/interfaces/form'
import SelectItem from '@/classes/Form/Fields/Select/SelectItem'
import TextAreaFieldTypes from '@/classes/Form/Fields/TextAreaFieldTypes'

@Component({
  components: {
    PageHeader,
    Form,
  },
})
export default class SettingsTabsFinanciersEdit extends Vue {
  private form: FormBase | null = null

  created(): void {
    this.fetchFundingRequestTypes()
  }

  private setFields(
    fundingRequestTypesIndividual: SelectItem[],
    fundingRequestTypesBusiness: SelectItem[],
    guaranteesOptions: SelectItem[],
    districtsOptions: SelectItem[],
  ): void {
    this.form = new FormBase()
      .setUuid(this.$router.currentRoute.params.uuid)
      .setFiles(true)
      .setEndpoint('financiers')
      .setModel(FinancierModel)
      .setFields([
        new Field()
          .setType(FieldTypes.toggle)
          .setEntryKey(FinancierAttribute.status)
          .setKey('status')
          .setTitle('Aktyvuoti')
          .setSize(FieldSizes.half),
        new Field().setKey('name').setTitle('Pavadinimas'),
        new Field().setType(FieldTypes.email).setSize(FieldSizes.half).setKey('email').setTitle('El. paštas'),
        new Field().setType(FieldTypes.file).setSize(FieldSizes.half).setKey('logo').setTitle('Logotipas').setMeta({
          type: 'image',
          accept: 'image/*',
        }),
        new TextAreaFieldTypes()
          .setSize(FieldSizes.half)
          .setKey('info_block')
          .setEntryKey(FinancierAttribute.infoBlock)
          .setTitle('Imones info')
          .setRows(1)
          .setAutoGrow(true),
        new Field()
          .setType(FieldTypes.text)
          .setEntryKey(FinancierAttribute.webUrl)
          .setSize(FieldSizes.half)
          .setKey('web_url')
          .setTitle('Nuoroda'),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey(FinancierAttribute.minAmount)
          .setKey('min_amount')
          .setTitle('Min. suma')
          .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey(FinancierAttribute.maxAmount)
          .setKey('max_amount')
          .setTitle('Maks. suma')
          .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey(FinancierAttribute.minPeriod)
          .setKey('min_period')
          .setTitle('Min. periodas')
          .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey(FinancierAttribute.maxPeriod)
          .setKey('max_period')
          .setTitle('Maks. periodas')
          .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.checkbox)
          .setEntryKey(FinancierAttribute.fundingRequestVisibility)
          .setKey('funding_request_visibility')
          .setOnChange(() => this.clearError('business_request_visibility'))
          .setTitle('Fizinės finansavimo užklausos')
          .setSize(FieldSizes.full),
        new SelectField()
          .isMultiple()
          .setKey(FinancierAttribute.fundingRequestTypes)
          .setTitle('Nerodyti šių užklausų tipų')
          .setItems(fundingRequestTypesIndividual)
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey(FinancierAttribute.minAge)
          .setKey('min_age')
          .setTitle('Min. amžius')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey(FinancierAttribute.maxAge)
          .setKey('max_age')
          .setTitle('Maks. amžius')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey(FinancierAttribute.minIncome)
          .setKey('min_income')
          .setTitle('Min. atlyginimas')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey(FinancierAttribute.maxIncome)
          .setKey('max_income')
          .setTitle('Maks. atlyginimas')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility),
        new SelectField()
          .isMultiple()
          .setKey(FinancierAttribute.workExperience)
          .setTitle('Nerodyti užklausų, kur darbo stažas')
          .setItems(workExperienceValues)
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey(FinancierAttribute.maxIncomeLiabilityDisparity)
          .setKey('max_income_liability_disparity')
          .setTitle('Maks. pajamų/įsipareigojimų skirtumas (Procentais)')
          .setSize(FieldSizes.half)
          .setAppend('%')
          .setVisibleIf((values: any) => !!values.funding_request_visibility),
        new SelectField()
          .isMultiple()
          .setKey(FinancierAttribute.districts)
          .setTitle('Rodyti šiems rajonams')
          .setItems(districtsOptions)
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility),
        new Field()
          .setType(FieldTypes.checkbox)
          .setEntryKey(FinancierAttribute.requiredBankAccount)
          .setKey('require_bank_account')
          .setTitle('Paraiškos, kurios turi banko sąskaitą')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility),

        new Field()
          .setType(FieldTypes.checkbox)
          .setEntryKey(FinancierAttribute.isSodraActive)
          .setKey('is_sodra_active')
          .setTitle('Tikrinti sodrą')
          .setSize(FieldSizes.full)
          .setVisibleIf((values: any) => !!values.funding_request_visibility),
        new SelectField()
          .setEntryKey('declared_in_lithuania')
          .setKey('declared_in_lithuania')
          .setTitle('Ar asmuo yra deklaruotas Lietuvoje')
          .setItems(optionsToSelectItems(declaredInLithuania))
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),

        new Field()
          .setType(FieldTypes.message)
          .setKey('applicantMessage')
          .setTitle('Paraiškos teikėjas')
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.applicant.stable_average_income')
          .setKey('sodra_settings.applicant.stable_average_income')
          .setTitle('Stabilių pajamų vidurkis, EUR. Suma nuo kiek')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.applicant.not_stable_average_income')
          .setKey('sodra_settings.applicant.not_stable_average_income')
          .setTitle('Nestabilių pajamų vidurkis, EUR. Suma nuo kiek')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.applicant.total_average_income')
          .setKey('sodra_settings.applicant.total_average_income')
          .setTitle('Bendrų pajamų vidurkis, EUR. Suma nuo kiek')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.applicant.current_dsti')
          .setKey('sodra_settings.applicant.current_dsti')
          .setTitle('Dabartinis DSTI %. Iki kiek')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.applicant.max_dsti')
          .setKey('sodra_settings.applicant.max_dsti')
          .setTitle('Galimas maksimalus DSTI %. Iki kiek')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.applicant.total_monthly_payment')
          .setKey('sodra_settings.applicant.total_monthly_payment')
          .setTitle('Mėnesinių įsipareigojimų suma, EUR. Suma iki kiek')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new SelectField()
          .isMultiple()
          .setEntryKey('sodra_settings.applicant.cip_risk_grade')
          .setKey('sodra_settings.applicant.cip_risk_grade')
          .setTitle('CreditInfo Lietuva įvertis')
          .setItems(optionsToSelectItems(cipRiskGrade))
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.applicant.predicted_probability_of_default')
          .setKey('sodra_settings.applicant.predicted_probability_of_default')
          .setTitle('Galimybė tapti nemokiam (įrašyti procentinę vertę)')
          .setAppend('%')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.applicant.available_monthly_payment')
          .setKey('sodra_settings.applicant.available_monthly_payment')
          .setTitle('Galimas kiekis paskolinti, EUR. Suma nuo kiek')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),

        new Field()
          .setType(FieldTypes.message)
          .setKey('spouseMessage')
          .setTitle('Paraiškos teikėjo sutuoktinis')
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.spouse.stable_average_income')
          .setKey('sodra_settings.spouse.stable_average_income')
          .setTitle('Stabilių pajamų vidurkis, EUR. Suma nuo kiek')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.spouse.not_stable_average_income')
          .setKey('sodra_settings.spouse.not_stable_average_income')
          .setTitle('Nestabilių pajamų vidurkis, EUR. Suma nuo kiek')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.spouse.total_average_income')
          .setKey('sodra_settings.spouse.total_average_income')
          .setTitle('Bendrų pajamų vidurkis, EUR. Suma nuo kiek')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.spouse.current_dsti')
          .setKey('sodra_settings.spouse.current_dsti')
          .setTitle('Dabartinis DSTI %. Iki kiek')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.spouse.max_dsti')
          .setKey('sodra_settings.spouse.max_dsti')
          .setTitle('Galimas maksimalus DSTI %. Iki kiek')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.spouse.total_monthly_payment')
          .setKey('sodra_settings.spouse.total_monthly_payment')
          .setTitle('Mėnesinių įsipareigojimų suma, EUR. Suma iki kiek')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new SelectField()
          .isMultiple()
          .setEntryKey('sodra_settings.spouse.cip_risk_grade')
          .setKey('sodra_settings.spouse.cip_risk_grade')
          .setTitle('CreditInfo Lietuva įvertis')
          .setItems(optionsToSelectItems(cipRiskGrade))
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.spouse.predicted_probability_of_default')
          .setKey('sodra_settings.spouse.predicted_probability_of_default')
          .setTitle('Galimybė tapti nemokiam (įrašyti procentinę vertę)')
          .setAppend('%')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.spouse.available_monthly_payment')
          .setKey('sodra_settings.spouse.available_monthly_payment')
          .setTitle('Galimas kiekis paskolinti, EUR. Suma nuo kiek')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),

        new Field()
          .setType(FieldTypes.message)
          .setKey('bothMessage')
          .setTitle('Paraiškos teikėjas ir sutuoktinis')
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),
        new Field()
          .setType(FieldTypes.number)
          .setEntryKey('sodra_settings.both.stable_average_income')
          .setKey('sodra_settings.both.stable_average_income')
          .setTitle('Stabilių pajamų vidurkis, EUR. Suma nuo kiek')
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.funding_request_visibility && values.is_sodra_active),

        new Field()
          .setType(FieldTypes.checkbox)
          .setEntryKey(FinancierAttribute.businessRequestVisibility)
          .setKey('business_request_visibility')
          .setOnChange(() => this.clearError('business_request_visibility'))
          .setTitle('Verslo finansavimo užklausos')
          .setSize(FieldSizes.full),
        new SelectField()
          .isMultiple()
          .setKey(FinancierAttribute.companyActivityPeriod)
          .setTitle('Nerodyti šių įmonės veiklos laikotarpių')
          .setItems(optionsToSelectItems(companyExistanceDuration))
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.business_request_visibility),
        new SelectField()
          .isMultiple()
          .setKey(FinancierAttribute.companyYearlyIncome)
          .setTitle('Nerodyti šių įmonės metinių apyvartų')
          .setItems(optionsToSelectItems(companyRevenueYearly))
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.business_request_visibility),
        new SelectField()
          .isMultiple()
          .setKey(FinancierAttribute.companyFundingPurpose)
          .setTitle('Nerodyti šių paskolos tikslų')
          .setItems(fundingRequestTypesBusiness)
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.business_request_visibility),
        new SelectField()
          .isMultiple()
          .setKey(FinancierAttribute.companyFundingGuarantees)
          .setTitle('Rodyti šias finansavimo užtikrinimo priemones')
          .setItems(guaranteesOptions)
          .setSize(FieldSizes.half)
          .setVisibleIf((values: any) => !!values.business_request_visibility),
        new Field()
          .setType(FieldTypes.checkbox)
          .setEntryKey(FinancierAttribute.displayCompanyPdf)
          .setKey(FinancierAttribute.displayCompanyPdf)
          .setTitle('Įmonės PDF ataskaita')
          .setVisibleIf((values: any) => !!values.business_request_visibility)
          .setSize(FieldSizes.half),
      ])
      .setOnSuccess(this.onSuccess)
  }

  fetchFundingRequestTypes(): Promise<void> {
    return http
      .get(`/funding-types`)
      .then(async (data: any) => {
        const individualTypes = data.data.data.filter((item: IFundingRequestType) => item.type === 'individual')

        const businessTypes = data.data.data.filter((item: IFundingRequestType) => item.type === 'business')

        const fundingRequestTypesIndividual = this.transformDataToOptions(individualTypes)

        const fundingRequestTypesBusiness = this.transformDataToOptions(businessTypes)

        const response = await http.get(`funding-requests/initial-data`)

        const guaranteeOptions = this.transformGuaranteesDataToOptions(response.data.guarantee_types)

        const districtsOptions = this.transformDistrictDataToOptions(response.data.districts)

        this.setFields(fundingRequestTypesIndividual, fundingRequestTypesBusiness, guaranteeOptions, districtsOptions)
      })
      .catch(async (error: AxiosError) => {
        if (error.response) {
          await this.$store.dispatch(Actions.showSnackbar, {
            type: 'error',
            message: error.response.data.message,
          })
        }
      })
  }

  transformDataToOptions(options: IFundingRequestType[]): SelectItem[] {
    return options.map((item) => new SelectItem().setValue(item.key).setTitle(item.name))
  }

  transformGuaranteesDataToOptions(options: IGuaranteesType[]): SelectItem[] {
    return options.map((item) => new SelectItem().setValue(item.value).setTitle(item.name))
  }

  transformDistrictDataToOptions(options: IDistricts[]): SelectItem[] {
    return options.map((item) => new SelectItem().setValue(item.uuid).setTitle(item.title))
  }

  onSuccess(response: any, entry: any): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: `Redagavimas sėkmingas finansuotojui pavadinimu - ${entry.name}`,
    })
    this.goToList()
  }

  goToList(): void {
    this.$router.push({ name: AdminRoutes.settingsFinanciersIndex })
  }

  clearError(key: string): void {
    if (this.form && this.form.errors) {
      this.$delete(this.form.errors, key)
    }
  }
}
