export enum EFinancierDeclaredIn {
  yes = 'yes',
  no = 'no',
  skip = 'skip',
}

export enum EFinancierRiskGrade {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}
