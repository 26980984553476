import Model from '@/classes/Model'
import IFinancierResponse from '@/modules/financier/financier-response.interface'
import FundingRequestFinancierInfoModel from '@/modules/funding-request-financier-info/funding-request-financier-info.model'
import IFundingRequestFinancierInfoResponse from '@/modules/funding-request-financier-info/funding-request-financier-info-response.interface'
import ISodraSettings, { ISodraSettingsPerson } from '@/modules/financier/financier-sodra-settings.interface'
import IDebtSettings, { IDebtSettingsPerson } from '@/modules/financier/financier-debt-settings.interface'
import { EFinancierDeclaredIn, EFinancierRiskGrade } from '@/modules/financier/financier-settings.enum'
import IOption from '@/interfaces/option.interface'

export const cipRiskGrade: IOption[] = [
  {
    title: 'A',
    key: EFinancierRiskGrade.A,
  },
  {
    title: 'B',
    key: EFinancierRiskGrade.B,
  },
  {
    title: 'C',
    key: EFinancierRiskGrade.C,
  },
  {
    title: 'D',
    key: EFinancierRiskGrade.D,
  },
  {
    title: 'E',
    key: EFinancierRiskGrade.E,
  },
]

export const declaredInLithuania: IOption[] = [
  {
    title: 'Taip',
    key: EFinancierDeclaredIn.yes,
  },
  {
    title: 'Ne',
    key: EFinancierDeclaredIn.no,
  },
  {
    title: 'Nesvarbu',
    key: EFinancierDeclaredIn.skip,
  },
]

export enum FinancierAttribute {
  name = 'name',
  apiKey = 'apiKey',
  secretKey = 'secretKey',
  email = 'email',
  logo = 'logo',
  minAmount = 'minAmount',
  maxAmount = 'maxAmount',
  isActive = 'isActive',
  status = 'status',
  minPeriod = 'minPeriod',
  maxPeriod = 'maxPeriod',
  minAge = 'minAge',
  maxAge = 'maxAge',
  minIncome = 'minIncome',
  maxIncome = 'maxIncome',
  fundingRequestTypes = 'funding_request_types',
  districts = 'funding_request_districts',
  fundingRequestBusinessTypes = 'company_funding_purpose',
  workExperience = 'work_experience',
  isDeclined = 'is_declined',
  maxIncomeLiabilityDisparity = 'max_income_liability_disparity',
  requiredBankAccount = 'requireBankAccount',
  fundingRequestVisibility = 'funding_request_visibility',
  businessRequestVisibility = 'business_request_visibility',
  allInfoProvided = 'all_info_provided',

  companyActivityPeriod = 'company_activity_period',
  companyYearlyIncome = 'company_yearly_income',
  companyFundingPurpose = 'company_funding_purpose',
  companyFundingGuarantees = 'company_funding_guarantees',

  additionalDocuments = 'additional_documents',
  offers = 'offers',
  users = 'users',
  fundingRequestFinancierInfo = 'funding_request_financier_info',
  provideAnswers = 'provide_answers',
  provideDocuments = 'provide_documents',
  infoBlock = 'infoBlock',
  webUrl = 'webUrl',
  displayCompanyPdf = 'display_company_pdf',

  isSodraActive = 'is_sodra_active',
  declaredInLithuania = 'declared_in_lithuania',
  sodraSettings = 'sodra_settings',
  debtsSettings = 'debts_settings',
}

export default class FinancierModel extends Model {
  [FinancierAttribute.name]!: string;
  [FinancierAttribute.apiKey]!: string;
  [FinancierAttribute.secretKey]!: string;
  [FinancierAttribute.email]!: string;
  [FinancierAttribute.logo]!: string;
  [FinancierAttribute.minAmount]!: number;
  [FinancierAttribute.maxAmount]!: number;
  [FinancierAttribute.isActive]!: boolean;
  [FinancierAttribute.status]!: string;
  [FinancierAttribute.minPeriod]!: number;
  [FinancierAttribute.maxPeriod]!: number;
  [FinancierAttribute.minAge]!: number;
  [FinancierAttribute.maxAge]!: number;
  [FinancierAttribute.minIncome]!: number;
  [FinancierAttribute.maxIncome]!: number;
  [FinancierAttribute.fundingRequestTypes]!: any;
  [FinancierAttribute.workExperience]!: any;
  [FinancierAttribute.maxIncomeLiabilityDisparity]!: number;
  [FinancierAttribute.isDeclined]!: boolean | any;
  [FinancierAttribute.additionalDocuments]!: any;
  [FinancierAttribute.users]!: any;
  [FinancierAttribute.offers]!: any;
  [FinancierAttribute.fundingRequestFinancierInfo]!: FundingRequestFinancierInfoModel[];
  [FinancierAttribute.provideAnswers]!: FundingRequestFinancierInfoModel[];
  [FinancierAttribute.provideDocuments]!: FundingRequestFinancierInfoModel[];
  [FinancierAttribute.requiredBankAccount]!: any;
  [FinancierAttribute.businessRequestVisibility]!: any;
  [FinancierAttribute.fundingRequestVisibility]!: any;
  [FinancierAttribute.allInfoProvided]!: boolean;
  [FinancierAttribute.companyActivityPeriod]!: any;
  [FinancierAttribute.companyYearlyIncome]!: any;
  [FinancierAttribute.companyFundingPurpose]!: any;
  [FinancierAttribute.companyFundingGuarantees]!: any;
  [FinancierAttribute.infoBlock]!: string | null;
  [FinancierAttribute.webUrl]!: string | null;
  [FinancierAttribute.displayCompanyPdf]!: boolean | null;
  [FinancierAttribute.districts]!: string[] | null;
  [FinancierAttribute.isSodraActive]!: boolean | null;
  [FinancierAttribute.declaredInLithuania]!: EFinancierDeclaredIn;
  [FinancierAttribute.sodraSettings]!: ISodraSettings | null;
  [FinancierAttribute.debtsSettings]!: IDebtSettings | null

  setPersonSodraSettings(data: ISodraSettingsPerson | null): ISodraSettingsPerson {
    return {
      stable_average_income: data?.stable_average_income || null,
      not_stable_average_income: data?.not_stable_average_income || null,
      total_average_income: data?.total_average_income || null,
      current_dsti: data?.current_dsti || null,
      max_dsti: data?.max_dsti || null,
      total_monthly_payment: data?.total_monthly_payment || null,
      cip_risk_grade: data?.cip_risk_grade || null,
      predicted_probability_of_default: data?.predicted_probability_of_default || null,
      available_monthly_payment: data?.available_monthly_payment || null,
    }
  }

  setPersonDebtSettings(data: IDebtSettingsPerson | null): IDebtSettingsPerson {
    return {
      debts_of_financial_institution: data?.debts_of_financial_institution || null,
      debts_of_collection_companies: data?.debts_of_collection_companies || null,
      debts_of_everyone_else: data?.debts_of_everyone_else || null,
    }
  }

  transform(data: IFinancierResponse): FinancierModel {
    super.transform(data)

    this.name = data.name
    this.apiKey = data.api_key
    this.secretKey = data.secret_key
    this.email = data.email
    this.logo = data.logo
    this.minAmount = data.min_amount
    this.maxAmount = data.max_amount
    this.infoBlock = data.info_block
    this.webUrl = data.web_url
    this.isActive = data.is_active
    this.status = data.status
    this.minPeriod = data.min_period
    this.maxPeriod = data.max_period
    this.minAge = data.min_age
    this.maxAge = data.max_age
    this.minIncome = data.min_income
    this.maxIncome = data.max_income
    this.funding_request_types = data.funding_request_types
    this.offers = data.offers
    this.work_experience = data.work_experience
    this.max_income_liability_disparity = data.max_income_liability_disparity
    this.is_declined = data.is_declined
    this.is_declined = data.is_declined
    this.funding_request_districts = data.funding_request_districts

    this.additional_documents = data.additional_documents
    this.users = data.users

    this.company_activity_period = data.company_activity_period
    this.company_yearly_income = data.company_yearly_income
    this.company_funding_purpose = data.company_funding_purpose
    this.company_funding_guarantees = data.company_funding_guarantees

    if (data.funding_request_financier_info) {
      this.funding_request_financier_info = data.funding_request_financier_info.map(
        (item: IFundingRequestFinancierInfoResponse) => new FundingRequestFinancierInfoModel().transform(item),
      )
    }

    if (data.provide_answers) {
      this.provide_answers = data.provide_answers.map((data: IFundingRequestFinancierInfoResponse) =>
        new FundingRequestFinancierInfoModel().transform(data),
      )
    }

    if (data.provide_documents) {
      this.provide_documents = data.provide_documents.map((data: IFundingRequestFinancierInfoResponse) =>
        new FundingRequestFinancierInfoModel().transform(data),
      )
    }

    this.requireBankAccount = data.require_bank_account
    this.funding_request_visibility = data.funding_request_visibility
    this.business_request_visibility = data.business_request_visibility
    this.all_info_provided = data.all_info_provided
    this.display_company_pdf = data.display_company_pdf

    this.is_sodra_active = data.is_sodra_active || false
    this.declared_in_lithuania = data.declared_in_lithuania || EFinancierDeclaredIn.skip
    this.sodra_settings = {
      applicant: this.setPersonSodraSettings(data?.sodra_settings?.applicant || null),
      spouse: this.setPersonSodraSettings(data?.sodra_settings?.spouse || null),
      both: {
        stable_average_income: data?.sodra_settings?.both?.stable_average_income || null,
      },
    }
    this.debts_settings = {
      applicant: this.setPersonDebtSettings(data?.debts_settings?.applicant || null),
      spouse: this.setPersonDebtSettings(data?.debts_settings?.spouse || null),
    }

    return this
  }
}
