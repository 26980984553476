



















































































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import _ from 'lodash'
import http from '@/http'
import { AxiosResponse } from 'axios'
import IFundingRequestResponse from '@/modules/funding-request/funding-request-response.interface'
import IFundingRequestFieldResponse from '@/modules/funding-request-field/funding-request-field-response.interface'
import FundingRequestFieldModel from '@/modules/funding-request-field/funding-request-field.model'
import FundingRequestModel from '@/modules/funding-request/funding-request.model'
import { FieldTypes } from '@/components/Form/FieldTypes'
import { Getters } from '@/store/auth/getters'
import UserModel from '@/modules/user/user.model'
import permissions from '@/helpers/permissions'

@Component({
  components: {
    PageHeader,
  },
})
export default class Dsti extends Vue {
  dstiFields: FundingRequestFieldModel[] | null = null
  fundingRequest: FundingRequestModel | null = null
  user: UserModel = this.$store.getters[Getters.getUser]
  permissions = permissions

  async created(): Promise<void> {
    await this.loadFundingRequest()
  }

  get uniqueDstiFields(): FundingRequestFieldModel[] {
    if (!this.dstiFields) return []

    return _.uniqBy(this.dstiFields, 'key')
  }

  noInfoToString(fieldValue: any): any {
    if (!fieldValue && fieldValue !== 0) {
      return 'Nėra informacijos'
    }

    return fieldValue
  }

  isFieldBoth(fieldKey: string): boolean {
    return fieldKey === 'stableIncomeBoth' || fieldKey === 'unstableIncomeBoth' || fieldKey === 'totalIncomeBoth'
  }

  loadFundingRequest(): void {
    http.get(`/funding-requests/${this.$router.currentRoute.params.uuid}`).then(
      (
        response: AxiosResponse<{
          funding_request: IFundingRequestResponse
          dsti_fields: IFundingRequestFieldResponse[]
        }>,
      ) => {
        this.dstiFields = response.data.dsti_fields.map((item: IFundingRequestFieldResponse) =>
          new FundingRequestFieldModel().transform(item),
        )
        this.fundingRequest = new FundingRequestModel().transform(response.data.funding_request)
      },
    )
  }

  getValue(data: object | undefined, field: FundingRequestFieldModel): string | false {
    if (!data) return false

    if (field.type === 'json') return false

    if (field.type === FieldTypes.select) {
      const found = field.options.find((option: any) => option.value === _.get(data, field.key))

      return found ? found.label : _.get(data, field.key, false)
    }

    if (field.type === FieldTypes.checkbox) return _.get(data, field.key) ? 'Taip' : 'Ne'

    if (field.type === FieldTypes.number) return _.get(data, field.key, null)

    return _.get(data, field.key)
  }
}
